<template>
  <div>
    <FormulaBanner :formulaCode="formulaCode" :title="title"/>
    <div class="index">
      <!-- 表单 -->
      <div>
        <div class="top_nav"><span></span>{{ title }}
        </div>
        <div class="formBody">
          <!-- 表单 -->
          <MyPatient :memberId="memberId" :selectValue="selectValue" @change="userChange"/>
          <TemplateFiveList :list-data="listData" :selectValue="listSelect" :type="'radio'" @change="listChange"></TemplateFiveList>
          <TemplateFiveList v-if="!listSelect.some(item => item.value.includes('A.无反应') || item.value.includes('B.对于加强的和重复刺激有反应'))" :list-data="listDataTree" :select-value="listSelectTree" :type="'radio'" @change="listChangeTree"></TemplateFiveList>
          <div v-if="compute === true" style="margin: 16px;display: flex;justify-content: center;">
            <van-button block class="formula-template-button" type="info" @click="submit" style="width:90px">提交</van-button>
          </div>
          <!--          <div v-if="countText || countText !== ''" class="CalculationResultsBox">-->
          <!--            <div class="CalculationResults"><span>计算结果</span></div>-->
          <!--            &lt;!&ndash; 具体标准 &ndash;&gt;-->
          <!--            <div>-->
          <!--              <div class="CalculationDetail"><span></span>{{ result }}分 {{ countText }}</div>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>
      <CalculationResults :countText="countValue" :count-text-list="[]" v-if="show"></CalculationResults>
      <!-- 后台设置的值 -->
      <FormulaExplain :explain-data="tableData.explain"></FormulaExplain>
      <!-- 参考文献 -->
      <FormulaReferences :references-data="tableData.references"></FormulaReferences>
    </div>

    <van-popup v-model="showPicker" position="bottom">
      <van-picker
          :columns="columns"
          show-toolbar
          title="应激指数"
          @cancel="onCancel"
          @confirm="onConfirm"
      />
    </van-popup>

  </div>

</template>

<script>
import MyPatient from '@/components/MyPatient.vue'
import FormulaBanner from '@/components/FormulaBanner.vue'
import {Toast} from "vant";
import {getAdmins} from "@/utils/adminDate";
import {setById} from "@/service/base";
import {getFormula, getFormulaHuiXian, postFormula} from "@/service/BodyMassIndex_api";
import FormulaExplain from "@/components/template1/FormulaExplain";
import FormulaReferences from "@/components/template1/FormulaReferences";
import TemplateFiveList from "@/components/Template5/TemplateFiveList";
import CalculationResults from "@/components/CalculationResults.vue";

export default {
  name: "medicalSearch",
  data() {
    return {
      compute:true,
      show:false,
      listSelect: [],
      listSelectTree: [],
      listData: [
        {
          title: '意识变化水平',
          tips: '(如果为A或者B，该期间暂时终止评价)',
          columns: ['A.无反应', 'B.对于加强的和重复刺激有反应', 'C.对于轻度或者中度刺激有反应', 'D.正常清醒', 'F.对正常刺激产生夸大的反应'],
          fenzhi: [0, 0, 1, 0, 1] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        },
      ],
      listDataTree: [
        {
          title: '注意力不集中',
          columns: ['无', '有',],
          fenzhi: [0, 1] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        },        {
          title: '定向力障碍',
          columns: ['无', '有',],
          fenzhi: [0, 1] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        },
        {
          title: '幻觉-幻想性精神病状态',
          columns: ['无', '有',],
          fenzhi: [0, 1] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        },
        {
          title: '精神运动型激越或者阻滞',
          columns: ['无', '有',],
          fenzhi: [0, 1] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        },
        {
          title: '不恰当的语言和情绪',
          columns: ['无', '有',],
          fenzhi: [0, 1] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        },
        {
          title: '睡眠-觉醒周期失调',
          columns: ['无', '有',],
          fenzhi: [0, 1] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        },
        {
          title: '症状波动',
          columns: ['无', '有',],
          fenzhi: [0, 1] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        },
      ],
      formulaCode: '',
      shengao: '',
      yingjizhishu: '',
      title: '',
      memberId: '1111',
      selectValue: '',
      username: "",
      value: "",
      showPopover: false,
      showPicker: false,
      columns: ['立即治疗', '加强监测', '维持和监护'],
      nianling: '',
      xingbie: null,
      tableData: {},
      countText: '',
      patientId: null,
      result: null,
      yingjizhishuzhi: '',
      tizhong: '',
      xueqingnanongdu: '',
      fenzhiList: [],
      isICU:undefined,
      countValue:"",
    }
  },
  components: {
    CalculationResults,
    FormulaBanner,
    MyPatient,
    FormulaExplain,
    FormulaReferences,
    TemplateFiveList,
  },
  async created() {
    await this.test()
    await this.getTableData()
  },

  methods: {
    listChange(result, fenzhi) {
      this.show=false
      this.listSelect = result //结果数组
      this.fenzhiList = fenzhi  //分值数组
      if (this.listSelect.some(item => item.value.includes('A.无反应') || item.value.includes('B.对于加强的和重复刺激有反应'))) {
        this.listSelectTree = []
      }
    },    listChangeTree(result) {
      this.show=false
      this.listSelectTree = result //结果数组
    },

    coumputTetxt() {
      this.show=true
      let fenshu = 0
      function calculateScore(data, select) {
        select.value.forEach(item => {
          const index = data.columns.indexOf(item);
          if (index !== -1) {
            fenshu += data.fenzhi[index];
          }
        });
      }
      this.listSelect.forEach(value => {
        const data = this.listData.find(d => d.title === value.title);
        if (data) {
          calculateScore(data, value);
        }
      });
      this.listSelectTree.forEach(value => {
        const data = this.listDataTree.find(d => d.title === value.title);
        if (data) {
          calculateScore(data, value);
        }
      });

      if (fenshu >= 4) {
        this.countText = '存在谵妄'
      } else if (fenshu < 4) {
        this.countText = '不存在谵妄'
      }
      this.result = fenshu
      this.countValue = `${fenshu}分 ${this.countText}`
    },
    async test() {
      //像接口头部传用户信息
      let arrays = await getAdmins(this.$route.query.memberId, this.$route.query.channel)
      this.channel = arrays.os
      this.version = arrays.version
      this.ip = arrays.ip
      await setById(arrays)
    },
    async getTableData() {
      const data = {
        channel: this.$router.currentRoute.query.channel,
        code: 'QKYRQIJF',
      }
      const res = await getFormula(data.channel, data.code)
      if (res.code === 0) {
        this.tableData = res.data
        this.formulaCode = res.data.code
        this.title = res.data.chineseName
      }
      if (this.$router.currentRoute.query.location === 'YXGS_RECORD_LIST' && this.$router.currentRoute.query.logId) {
        // this.compute = false
        const data = {
          id: this.$router.currentRoute.query.logId
        }
        const res = await getFormulaHuiXian(data)
        if (res.code === 0) {
          if (res.data.content.data && res.data.content.data.length > 0) {
            this.listSelect = res.data.content.data.map(item => {
              const list = this.listData.filter(row => row.title === item.title)
              return list && list.length > 0 ? item : null
            }).filter(title => title !== null);
            this.listSelectTree = res.data.content.data.map(item => {
              const list = this.listDataTree.filter(row => row.title === item.title)
              return list && list.length > 0 ? item : null
            }).filter(title => title !== null);
          }
          if (res.data.patientId) this.selectValue = {id: res.data.patientId, name: res.data.patientName}
          this.coumputTetxt()
        }
      }
    },
    async submit() {
      // if (!this.listSelect.length || this.listSelect.length === 0) {
      //   Toast.fail('请至少选择一项')
      //   return
      // }
      if ( this.listSelect.some(item => !item.value.includes('A.无反应') && !item.value.includes('B.对于加强的和重复刺激有反应')) && this.listSelectTree.length < 7) {
        Toast.fail('请完善选择')
        return
      }
      this.coumputTetxt()
      const data = {
        id: this.$router.currentRoute.query.location === 'YXGS_RECORD_LIST' ? this.$router.currentRoute.query.logId : null,
        patientId: this.patientId,
        columnCode: this.tableData.columnCode,
        formulaCode: this.tableData.code,
        channel: this.$router.currentRoute.query.channel,
        location: this.$router.currentRoute.query.location,
        content: {
          data: [...this.listSelectTree, ...this.listSelect],
        },
        result: {
          value: this.result,
          result: this.countText,
          DisplayResults:this.countValue
        }
      }
      const res = await postFormula(data)
      if (res.code === 0) {
        Toast.success('提交成功')
      } else {
        Toast.fail(res.message)
      }
    },
    calculateAge(birthDateString) {
      const today = new Date();
      const birthDate = new Date(birthDateString);
      let age = today.getFullYear() - birthDate.getFullYear();
      // 如果今天的日期还没到生日月份和日期，则年龄减一
      if (today.getMonth() < birthDate.getMonth() ||
          (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    // 开参考文献链接
    openLink(u) {
      if (!u) {
        return
      }
      //获取系统版本
      let userAI = navigator.userAgent;
      const isAndroid = userAI.indexOf('Android') > -1 || userAI.indexOf('Linux') > -1; //Android终端
      const isIOS = !!userAI.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
      if (isAndroid) {
        // alert('安卓操作系统')
        // 这个是安卓操作系统
        window.open(u)
      }
      if (isIOS) {
        // 这个是ios操作系统
        // alert('ios操作系统')
        window.location.href = u
      }
    },
    yingjiClick() {
      this.showPicker = true
    },
    onConfirm(value) {
      this.yingjizhishu = value
      this.showPicker = false
    },
    onCancel() {
      this.showPicker = false
    },
    userChange(data) {
      if (data.birthday && data.birthday !== '') {
        this.nianling = this.calculateAge(data.birthday)
      } else {
        this.nianling = ''
      }
      this.xingbie = data.sex
      this.patientId = data.id
      this.selectValue = data
    },
  }
}
</script>


<style lang="scss" scoped>
::v-deep .box-div-dis-zi{
  margin: 12px 0 !important;
}
.titlesName{
  font-size:16px;
  i{
    color: red;
  }
}
.index {
  background: #F6F6F6;
  padding: 10px 15px;
  min-height: 100vh;

  .StandardDescription {
    margin-top: 10px;

    .top_nav {
      font-size: 16px;
      color: #333333;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: start;
      margin-bottom: 10px;

      span {
        display: inline-block;
        width: 5px;
        height: 15px;
        background: #259beb;
        margin-right: 5px;
      }
    }

    .StandardDeatil {
      width: 100%;
      padding: 15px 10px;
      //   height: 50px;
      background-color: #fff;
      box-sizing: border-box;
    }
  }

  .top_nav {
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 10px;

    span {
      display: inline-block;
      width: 5px;
      height: 15px;
      background: #259beb;
      margin-right: 5px;
    }
  }

  .formBody {
    background-color: #fff;
    padding-bottom: 10px;

    .CalculationResultsBox {
      padding: 0 8px;

      .CalculationResults {
        font-size: 16px;
        color: #333333;
        font-weight: bold;
        display: flex;
        justify-content: center;
        margin-bottom: 10px
      }

      .CalculationDetail {
        font-size: 15px;
        color: #333333;
        // font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: start;
        margin-bottom: 10px;
        background-color: #f6f6f6;
        // height: 16px;
        span {
          display: inline-block;
          width: 3px;
          height: 24px;
          background: #fe9712;
          margin-right: 5px;
        }
      }
    }
  }

  .titles {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #f0f0f0;
    padding: 0 10px;
    box-sizing: border-box;

    .titlesName {
      font-size: 16px;

      i {
        color: red;
      }
    }

    .information {
      font-size: 16px;
      margin-right: 4px;

      .PleaseSelect {
        color: #b9b9b9;
      }

      .vanCell {
        width: 50px;
        padding: 0;
        height: 100%;
      }
    }

    .vBotton {
      width: 60px
    }

    .unit {
      height: 50px;
      //   line-height: 50px;
      display: flex;
      align-items: center;
      font-size: 16px;
      width: 20px;
    }

    .vanIcon {
      font-size: 13px;
      color: #a8a8a8;
      font-weight: 650;
    }
  }
}

::v-deep .van-cell {
  padding: 0px 0px;
}

::v-deep .van-field__control {
  height: 50px;
  font-size: 16px;
}

::v-deep .van-field__error-message {
  position: absolute;
  top: 30px;
  left: 10px;
}

.StandardDeatil2 {
  width: 100%;
  padding: 15px 10px;
  padding-bottom: 1px;
  //   height: 50px;
  background-color: #fff;
  box-sizing: border-box;

  .references {
    margin-bottom: 15px
  }
}

::v-deep p {
  padding: 0;
  margin: 0;
}

.Abutton {

}
.box-div {
  padding: 10px 10px;
  border-bottom: 1px solid #f0f0f0;
}

.box-div-dis {
  display: flex;
  color: #9A9A9A;
  flex-wrap: wrap;
  margin-top: 15px;
}
.box-div-dis-zi {
  display: flex;
  margin: 12px 5px;
  min-width: 50%;
}
</style>
